@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Poppins';
    src: url('./fonts/poppins-v20-latin-regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins Bold';
    src: url('./fonts/poppins-v20-latin-700.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PT Mono';
    src: url('./fonts/pt-mono-v13-latin-regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

b, strong {
    font-family: 'Poppins Bold', sans-serif;
}

body {
    margin: 0;
    background-color: #202A36;
}

/* Checkbox Toggle */
.toggler input:checked + label::after, .toggler input:checked + label .toggler-on, .toggler input:checked + label .toggler-off {
    left: 2.75rem;
}


.toggler input:checked + label .toggler-off, .toggler input:not(:checked) + label .toggler-on {
    width: 0;
    height: 0;
    opacity: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}


html {
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #344974 #1c242d;
}
::-webkit-scrollbar {
    width: 15px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece,
::-webkit-scrollbar-track-piece:hover,
::-webkit-scrollbar-track:hover {
    background-color: #1c242d;
}

::-webkit-scrollbar-thumb {
    background-color: #344974;
    border-radius: 15px;
    border: 6px solid #1c242d;
}

::-webkit-scrollbar-button {
     display:none
}

